$black-states-color: rgba(#000, 0.5);

.loading {
  background: $black-states-color;
  display: inline-block;
  height: 100%;
  width: 100%;

  .modal-dialog {
    display: table;
    position: relative;
    margin: 0 auto;
    top: calc(25% - 24px);
  }

  .modal-dialog .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.app {
  background: url('/assets/img/bg-image-sm.jpg') no-repeat center center fixed #000;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .default-header-img-avatar-container {
    height: 30px;
    width: 30px;
  }

  .sidebar-minimizer {
    position: relative !important;
  }

  .profile-img-avatar-container {
    display: inline-block;
    width: 50px;
  }
  
  .react-bs-container-header .table {
      margin-bottom: 0;
  }

  .clickable,
  .react-bs-container-header th.sort-column {
      cursor: pointer;
  }

  .user-avatar {
    width: 30px !important;
    height: 30px !important;
    overflow: hidden;
    border-radius: 50% !important;
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .navbar-brand-full {
    position: absolute;
    left: 45%;
  }
}

@media (max-width: 575.98px) {
  .footer-fixed .app-body {
    margin-bottom: 20px;
  }

  .footer-fixed .app-footer {
    font-size: 0.675em;
    height: 20px !important;
  }
}
